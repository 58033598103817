import { FC, useContext, useEffect, useState, useRef } from "react";
import GlobalContext from "src/context/global-context";
import { useNavigate, Navigate } from "react-router-dom";
import Dropdown from "src/components/ui/dropdown";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { JobType, MemberType } from "src/lib/data-types";
import InputWithoutLabel from "src/components/ui/input-without-label";
import Textarea from "src/components/ui/textarea";
import {
  calculateTotalHours,
  formatFileSize,
  formatTimeWithAMPM,
  getCurrentDateString,
  getCurrentTimeString,
} from "src/lib/number";
import Button from "src/components/ui/button";
import ActiveJobs from "src/components/active-job";
import Alert from "src/components/ui/alert";
import { Radio, RadioChangeEvent } from "antd";
import FileSelection from "src/components/file-selection";
import uploadImage from "src/lib/uploadImage";
import ProgressBar from "src/components/ui/progressbar";

const MainPage: FC = () => {
  const isMounted = useIsMounted();
  const {
    isAlertOpened,
    alertText,
    alertTitle,
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
  } = useContext(GlobalContext);
  const [progress, setProgress] = useState<any>({});
  //const [serverMode, setServerMode] = useState(1);
  // const serverMode = 1; //for internal
  const serverMode = 2; //for external

  const [memberID, setMemberID] = useState("");
  const [members, setMembers] = useState<MemberType[]>([]);

  const [location, setLocation] = useState("");
  const [todayDate, setTodayDate] = useState(getCurrentDateString());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [notes, setNotes] = useState("");

  const [isSubmitting, setSubmitting] = useState(false);
  const [bTimeError, setTimeError] = useState(false);

  const [selectedJobs, setSelectedJobs] = useState<JobType[]>([]);
  const [taskHoursPerJob, setTaskHoursPerJob] = useState<
    { jobId: string; hours: { taskId: string; hour: string }[] }[]
  >([]);

  const [completedWorksFiles, setCompletedWorksFiles] = useState<File[]>([]);
  const [completedWorksDescr, setCompletedWorksDescr] = useState("");

  const [referencePhotosFiles, setReferencePhotosFiles] = useState<File[]>([]);
  const [referencePhotosDescr, setReferencePhotosDescr] = useState("");

  const [otherPhotosFiles, setOtherPhotosFiles] = useState<File[]>([]);
  const [otherPhotosDescr, setOtherPhotosDescr] = useState("");

  /*
  const onServerModeChange = (e: RadioChangeEvent) => {
    setServerMode(e.target.value);
  };
  */

  const fetchMembers = async () => {
    const url = "https://hook.eu2.make.com/rh1uauj48h8ru8i4inox6bifnpqqg1py";
    const requestData = {
      event: "form loaded",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const members = data[0].body;

      setMembers(members);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    if (!isMounted) return;

    fetchMembers();
  }, [isMounted]);

  useEffect(() => {
    validateHours();
  }, [startTime, endTime, taskHoursPerJob]);

  const onSelectJobs = (
    jobs: JobType[],
    taskHoursPerJob: {
      jobId: string;
      hours: { taskId: string; hour: string }[];
    }[]
  ) => {
    setSelectedJobs(jobs);
    setTaskHoursPerJob(taskHoursPerJob);
  };

  const onReset = () => {
    setMemberID("");
    setSelectedJobs([]);
    setTaskHoursPerJob([]);

    setLocation("");
    setStartTime("");
    setEndTime("");
    setNotes("");

    setCompletedWorksFiles([]);
    setCompletedWorksDescr("");

    setOtherPhotosFiles([]);
    setOtherPhotosDescr("");

    setReferencePhotosFiles([]);
    setReferencePhotosDescr("");
  };

  const validateHours = () => {
    if (startTime.length == 0 && endTime.length == 0) {
      setTimeError(false);
      return false;
    }

    if (startTime.length == 0 || endTime.length == 0) {
      setTimeError(true);
      return false;
    }

    let totalHours = parseFloat(calculateTotalHours(startTime, endTime));
    console.log(totalHours);

    let totalTaskHours = 0;
    taskHoursPerJob.forEach((item) => {
      item.hours.forEach(
        (eachHours) => (totalTaskHours += Number(eachHours.hour) ?? 0)
      );
    });
    console.log(totalTaskHours);

    if (totalHours.toFixed(2) != totalTaskHours.toFixed(2)) {
      setTimeError(true);
      return false;
    }

    setTimeError(false);
    return true;
  };

  const onSubmit = async () => {
    let formData = new FormData();

    if (startTime.length == 0 || endTime.length == 0) {
      setTimeError(true);

      setAlertTitle("Error");
      setAlertText("Start and End time should be put!");
      setIsAlertOpened(true);

      return;
    }

    if (!validateHours()) {
      setAlertTitle("Error");
      setAlertText("Total Allocated Hours must match Total Hours.");
      setIsAlertOpened(true);

      return;
    }

    setSubmitting(true);
    formData.set("startTime", formatTimeWithAMPM(startTime));
    formData.set("endTime", formatTimeWithAMPM(endTime));
    formData.append(
      "totalHours",
      `${parseFloat(calculateTotalHours(startTime, endTime))}`
    );
    formData.append("event", "form submitted");
    formData.append("userId", memberID);
    formData.append("date", todayDate);
    location.length > 0 && formData.append("location", location);
    notes.length > 0 && formData.append("notes", notes);
    referencePhotosDescr.length > 0 &&
      formData.append("referencePhotosDescription", referencePhotosDescr);
    completedWorksDescr.length > 0 &&
      formData.append("completedWorksDescription", completedWorksDescr);
    otherPhotosDescr.length > 0 &&
      formData.append("otherPhotosDescription", otherPhotosDescr);

    setProgress({
      ...completedWorksFiles?.reduce(
        (acc, file) => ({
          ...acc,
          [file.name]: 0,
        }),
        {}
      ),
      ...referencePhotosFiles?.reduce(
        (acc, file) => ({
          ...acc,
          [file.name]: 0,
        }),
        {}
      ),
      ...otherPhotosFiles?.reduce(
        (acc, file) => ({
          ...acc,
          [file.name]: 0,
        }),
        {}
      ),
    });

    completedWorksFiles.length > 0 &&
      (await Promise.allSettled(
        completedWorksFiles.map((eachFile, index) =>
          uploadImage(eachFile, (newProgress) =>
            setProgress({ ...progress, [eachFile.name]: newProgress })
          ).then((url) =>
            formData.append(`completedWorks__${index}`, url || "")
          )
        )
      ));

    referencePhotosFiles.length > 0 &&
      (await Promise.allSettled(
        referencePhotosFiles.map((eachFile, index) =>
          uploadImage(eachFile, (newProgress) =>
            setProgress({ ...progress, [eachFile.name]: newProgress })
          ).then((url) =>
            formData.append(`referencePhotos__${index}`, url || "")
          )
        )
      ));

    otherPhotosFiles.length > 0 &&
      (await Promise.allSettled(
        otherPhotosFiles.map((eachFile, index) =>
          uploadImage(eachFile, (newProgress) =>
            setProgress({ ...progress, [eachFile.name]: newProgress })
          ).then((url) => formData.append(`otherPhotos__${index}`, url || ""))
        )
      ));

    const activeJobs = taskHoursPerJob.map((item) => {
      const tasks = item.hours.map((eachHours) => ({
        taskId: eachHours.taskId,
        allocatedHours: eachHours.hour,
      }));
      return { jobId: item.jobId, tasks };
    });

    taskHoursPerJob.forEach((item) => {
      item.hours.forEach((eachHours) =>
        formData.delete(`taskHours[${item.jobId}][${eachHours.taskId}]`)
      );
    });

    formData.append("activeJobs", JSON.stringify(activeJobs));

    const url = "https://hook.eu2.make.com/rzeg05ps1tcqiajpcse63b8blo4a9j2m";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      setSubmitting(false);

      if (response.ok) {
        // Call the functions on successful response
        setAlertTitle("Success");
        setAlertText("Form Submitted!");
        setIsAlertOpened(true);
        setTimeout(() => onReset(), 300);
      } else {
        console.error("Error:", response.statusText);
        setAlertTitle("Error");
        setAlertText(response.statusText);
        setIsAlertOpened(true);
      }
    } catch (error) {
      setSubmitting(false);

      console.error("Fetch error:", error);
      setAlertTitle("Error");
      setAlertText("Something went wrong!");
      setIsAlertOpened(true);
    }
  };

  if (isSubmitting) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {Object.keys(progress).map((key) => (
          <ProgressBar title={key} progress={progress[key]} />
        ))}
      </div>
    );
  }

  return (
    <div className="w-full bg-light-gray flex items-start justify-center min-h-screen p-4 md:p-12 ">
      <div className="w-full md:w-[80%] lg:w-[50%] bg-white flex flex-col gap-8 px-4 py-8 rounded-[8px] shadow-card">
        <div className="flex items-center justify-center min-h-[93px]">
          <img
            className="w-[128px] aspect-auto"
            src="https://static.wixstatic.com/media/714afd_9ed7b834431b4eed999eb17bcca5866c~mv2.png/v1/fill/w_128,h_93,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/white%20loci%20logo.png"
            alt="Logo"
          />
        </div>
        {/*
          <Radio.Group onChange={onServerModeChange} value={serverMode}>
            <Radio value={1}>Internal</Radio>
            <Radio value={2}>External</Radio>
          </Radio.Group>
        */}
        <div className="w-full">
          <Dropdown
            label={"Username"}
            className="w-full"
            value={memberID}
            onChange={(e: string) => setMemberID(e)}
            data={[{ id: "", fullName: "Select username" }, ...members]}
          />
        </div>
        <div className="w-full">
          <InputWithoutLabel
            type="date"
            label={"Date"}
            className="w-full"
            value={todayDate}
            onChange={(e) => setTodayDate(e.target.value)}
          />
        </div>
        <div className="w-full">
          <InputWithoutLabel
            type="time"
            label={"Start Time"}
            className="w-full"
            value={startTime}
            error={bTimeError}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div className="w-full">
          <InputWithoutLabel
            type="time"
            label={"End Time"}
            className="w-full"
            error={bTimeError}
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        {startTime.length != 0 && endTime.length != 0 && (
          <label className="w-full text-error text-[14px] font-bold">
            {`Please allocate ${parseFloat(
              calculateTotalHours(startTime, endTime)
            )} hours below`}
          </label>
        )}
        <ActiveJobs
          serverMode={serverMode}
          userId={memberID}
          onSelect={onSelectJobs}
          timeError={bTimeError}
        />
        <div className="w-full">
          <InputWithoutLabel
            label={"Location [On Site]"}
            className="w-full"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <FileSelection
          label="Completed Works"
          curFiles={completedWorksFiles}
          curDescr={completedWorksDescr}
          onFileChange={(files: File[]) => setCompletedWorksFiles(files)}
          onDescrChange={(strDescr: string) => setCompletedWorksDescr(strDescr)}
        />
        <FileSelection
          label="Reference Photos"
          curFiles={referencePhotosFiles}
          curDescr={referencePhotosDescr}
          onFileChange={(files: File[]) => setReferencePhotosFiles(files)}
          onDescrChange={(strDescr: string) =>
            setReferencePhotosDescr(strDescr)
          }
        />
        <FileSelection
          label="Other Photos"
          curFiles={otherPhotosFiles}
          curDescr={otherPhotosDescr}
          onFileChange={(files: File[]) => setOtherPhotosFiles(files)}
          onDescrChange={(strDescr: string) => setOtherPhotosDescr(strDescr)}
        />
        <div className="w-full">
          <Textarea
            label={"Notes"}
            className="w-full"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-center items-center w-full">
          <Button
            variant="validate"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            className="rounded-[10px] text-[14px] font-bold leading-[24px] h-[44px] w-full sm:!px-12"
            onClick={onSubmit}
          >
            {"Submit"}
          </Button>
        </div>
      </div>
      <Alert
        isOpened={isAlertOpened}
        setIsOpened={setIsAlertOpened}
        text={alertText}
        title={alertTitle}
      />
    </div>
  );
};

export default MainPage;
